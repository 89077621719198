const firebaseConfig = {
  apiKey: 'AIzaSyCA9JVRXc7uSMjQWu37TQKlDOuYTQ1FxJU',
  authDomain: 'the-business-directory-adb04.firebaseapp.com',
  projectId: 'the-business-directory-adb04',
  storageBucket: 'the-business-directory-adb04.appspot.com',
  messagingSenderId: '872309185008',
  appId: '1:872309185008:web:d2541dbf83c2cae1e4e535',
  measurementId: 'G-R47JZ194NJ',
};

export default firebaseConfig;
