export default [
  'Garden',
  'Movies',
  'Toys',
  'Outdoors',
  'Automotive',
  'Electronics',
  'Industrial',
  'Tools',
  'Home',
  'Grocery',
  'Games',
  'Beauty',
  'Clothing',
  'Shoes',
  'Baby',
  'Computers',
  'Health',
  'Books',
  'Jewelry',
  'Sports',
  'Kids',
  'Music',
];
