export default [
  'Sales',
  'Accounting',
  'Product Management',
  'Services',
  'Engineering',
  'Human Resources',
  'Marketing',
  'Business Development',
  'Training',
  'Legal',
  'Research and Development',
  'Support',
  'Advertising',
  'Secretarial Services',
  'General Office Services',
  'General Business',
  'Printing',
  'Retail Services',
  'Consultants',
  'Market Research',
  'Overseas Business',
  'Cleaning Equipment & Services',
  'Security Services',
  'Small Business',
  'Business Centres',
  'Health and Safety',
  'Laundry & Dry Cleaning',
  'Public Relations',
  'Fire Safety Consultants',
  'Brokers',
  'Waste Disposal Services',
  'Sales Outsourcing',
  'Business to Business',
  'Storage Services',
  'AC Repair Services',
  'Auctioneers',
  'Nanny Agency',
  'Kennels',
  'Baby Sitters',
  'Recycling',
  'Pet Grooming',
  'Tree Service',
  'Animal Shelters',
  'Garbage Removal',
  'Membership Services',
  'Tailors and Alterations',
  'Junk Dealers',
  'Dog Walking',
  'Timeshares',
  'Cremation Service',
  'Adoption Agencies',
  'Computers & Internet',
  'Communications',
  'Web Design',
  'Computer Accessories',
  'Computer Services',
  'Information Technology',
  'Networking',
  'Computer Software',
  'Computer Training',
  'Web Development',
  'Computers Hardware',
  'Web Services',
  'Online Content',
  'Internet Service Providers',
  'Web Hosting',
  'Computer Repair',
  'Computer Consumables',
  'Cryptocurrency',
  'Mail Services',
  'Scanning Services',
  'Entertainment & Media',
  'Pubs and Clubs',
  'Film, Television and Video',
  'Fashion',
  'Photography',
  'Leisure',
  'Arts and Crafts',
  'Sports',
  'Music',
  'Parties',
  'Pets and Animals',
  'Media Houses',
  'Bookmakers',
  'Lifestyle Management',
  'Casinos',
  'Hobbies',
  'Culture',
  'Performing Arts',
  'Gambling',
  'Events & Conferences',
  'Event Services',
  'Weddings',
  'Conferences',
  'Event Equipment',
  'Finances & Insurance',
  'Banks, Credit Unions',
  'Business Management Consulting',
  'Insurance Companies',
  'Financial Activity',
  'Audit and Accounting',
  'Investment Companies',
  'Tax Consultants',
  'Leasing',
  'Legal Services',
  'Payroll Services',
  'Banking Equipment',
  'Money Service Business',
  'Pawnshops',
  'Dept Counseling',
  'Food & Drink',
  'Restaurants',
  'Food Retailers',
  'Catering',
  'Food Manufacturing',
  'Wine and Beer',
  'Catering Equipment',
  'Take Aways',
  'Cookery',
  'Cold Storage Services',
  'Cafes',
  'Water Delivery Services',
  'Food Distributors',
  'Health & Beauty',
  'Doctors and Clinics',
  'Medical Equipment',
  'Pharmacies',
  'Pregnancy and Child Birth',
  'Beauty Products',
  'Beauty Professionals',
  'Hairdressers',
  'Health Care',
  'Fitness',
  'Nursing and Care',
  'Complementary Therapy',
  'Opticians',
  'Dentists',
  'Mental Health Care',
  'Massage Therapists',
  'Personal Care',
  'Vitamins and Supplements',
  'Mobility Aids',
  'Cosmetic Surgery',
  'Spirituality',
  'Sewing and Needlework',
  'Gynecologist',
  'Lawyers',
  'Solicitors',
  'Wills and Trusts',
  'Manufacturing & Industry',
  'Farming',
  'Energy Suppliers',
  'Mining',
  'Industrial Services',
  'Industrial Equipment',
  'Civil Engineering',
  'Steel Products',
  'Automotive',
  'Water Treatment',
  'Electrical Service',
  'Oil & Gas Companies',
  'Industrial Premises',
  'Engineers',
  'Industrial Supplies',
  'Industrial Automation',
  'Paper Products',
  'Product Development',
  'Furniture Manufacturers',
  'Pumps Manufacturers',
  'Fishing',
  'Solar Panels',
  'Disinfecting Services',
  'Science',
  'Marble & Granite',
  'Carpentry',
  'Aerospace',
  'Fisheries',
  'Utilities',
  'Firearms',
  'Hunting',
  'Shopping',
  'Electrical Goods',
  'Clothing and Accessories',
  'Home and Garden',
  'Furniture',
  'Gifts',
  'Mobile Phone Shops',
  'Books',
  'Jewellery',
  'Kids',
  'Electronic Equipment',
  'Textile',
  'Shopping Centres',
  'Shops',
  'Optical Shops',
  'Gadgets',
  'Department Stores',
  'Pet Shops',
  'Hardware Stores',
  'Camping and Outdoors',
  'Cigars and Tobacco',
  'Duty Free Shop',
  'Brand Name',
  'Audio & Video Equipment',
  'Fishing Equipment',
  'Discount Stores',
  'Secondhand Stores',
  'Outlets',
  'Tourism & Accommodation',
  'Hotels',
  'Travel Agents',
  'Guest Houses',
  'Tour Operators',
  'Apartments',
  'Tourist Information',
  'Attractions',
  'Visa Agencies',
  'Specialist Accommodation',
  'Translation Services',
  'Sightseeing',
  'Hotel & Motel Equipment',
  'Places to Visit',
  'Excursions',
  'Camping and Caravans',
  'Bed and Breakfast',
  'Holiday Homes',
  'Homestay',
  'Self Catering Accommodation',
  'Cottages',
  'Recreation',
  'Tradesmen & Construction',
  'Building Materials',
  'Contractors',
  'Chemicals',
  'Building Maintenance',
  'Architectural Services',
  'Construction Services',
  'Decorators',
  'Construction',
  'Aluminium',
  'Gardening',
  'Construction Equipment',
  'Plumbers',
  'Water Filters',
  'Pest Control',
  'Roofing',
  'Stainless Steel',
  'Construction Training',
  'Metals',
  'Doors',
  'Tempered Glass Railing',
  'Handyman',
  'Remodeling',
  'Windows',
  'Concrete',
  'Fencing and Fence Materials',
  'Drywall',
  'Paving',
  'Locksmiths',
  'Stone',
  'Excavators',
  'Staircase Timber Handrail',
  'Sandblasting',
  'Wrought Iron',
  'Exterminating and Disinfecting',
  'Glass Manufacturing',
  'Lumber',
  'Forestry',
  'Transport & Motoring',
  'Car Parts and Accessories',
  'Logistics',
  'Shipping & Port Agent',
  'Car Rental',
  'Transport',
  'Vehicle Services',
  'Car Washes',
  'Vehicle Sales',
  'Air Travel',
  'Marine Services',
  'Haulage',
  'Auto Dealers- New & Used',
  'Driving Schools',
  'Tire Dealers',
  'Air Transport',
  'Van & Truck Hire',
  'Auto Insurance',
  'Courier Services',
  'Transport Agents',
  'Motorbikes',
  'Airlines Offices',
  'Airports',
  'Petrol Station',
  'Package Shipping',
  'Cargo Services',
  'Auto Parts- New & Used',
  'Taxis',
  'Auto Repair',
  'Vehicle Manufacturers',
  'Auto Supplies',
  'Bicycles',
  'Auto Services',
  'Mechanics',
  'Aviation',
  'Ports and Harbours',
  'Bus Line',
  'Towing Service',
  'Boats and Boating',
  'Cruises',
  'Limousines',
  'Rail Transport',
  'Public & Social Services',
  'Associations',
  'Education',
  'Schools',
  'Public Services',
  'Colleges',
  'Police Departments',
  'Embassies & Consulates',
  'Universities',
  'Religion',
  'Churches',
  'Social Services',
  'Voluntary Organisations',
  'Environmental Services',
  'Information Services',
  'Charity Services',
  "Children's Services",
  'Nonprofit Organizations',
  'Business Education',
  'Libraries',
  'Government Services',
  'Charity',
  'Public Transport',
  'Government Offices',
  'Advice Centres',
  'Funeral Directors',
  'After School Programs',
  'Anti Corruption Organizations',
  'Organizations',
  'Counseling Services',
  'Youth Organizations',
  'Local Authorities',
  'Tutoring',
  'Child Daycare Services',
  'E-Learning & Virtual Classrooms',
  'Post Offices',
  'Kindergartens',
  'Group Homes',
  'Political',
  'Property',
  'Real Estate Agents',
  'Property Development',
  'Security',
  'Interior Design',
  'Property Management',
  'Cleaning',
  'Surveyors & Valuers',
  'Property Consultants',
  'Fire Safety Equipment',
  'Renovation',
  'Home Remodeling',
  'Letting Agents',
  'Removals and Relocation',
  'Commercial Property',
  'Buildings',
  'Online Property Listings',
  'Realtors',
  'Apartment Rental',
  'Home Improvement',
  'Overseas Property',
  'Auctions',
  'Warehouses',
  'Vacation Rentals',
];
